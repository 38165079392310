// Import React Library
import React from 'react';

// Importar el componente principal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Importar los íconos necesarios
import { faBuilding , faMap , faBoxesStacked} from "@fortawesome/free-solid-svg-icons";

// Import React Reveal Animation
import { Fade } from "react-awesome-reveal";

// Import Images
import officeImage from '../assets/images/camino-ventura-background-2.png';
import depositImage from '../assets/images/camino-ventura-background-4.jpg';
import fieldImage from '../assets/images/camino-ventura-background-5.jpg';

// Services Component
const Services = () => {
    return (
        <div id='services' className='services-section'>
            <h2 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span class="block xl:inline">Nuestros </span> 
                <span class="block text-primary xl:inline text-blue-900">Servicios</span>
            </h2>

            <div className='services'>
                <div className='service'>
                    <div className='service-content'>
                        <FontAwesomeIcon icon={faBuilding} className='icon-service mb-2 text-slate-700'/>
                        <h3 class="text-1xl tracking-tight text-slate-700 sm:text-5xl md:text-6xl my-2">
                            Oficinas
                        </h3>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            VARIEDAD DE ESPACIOS DE OFICINAS
                        </p>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            Ofrecemos oficinas de última generación y variedad de metrajes, dando a las empresas la flexibilidad de elegir el espacio que mejor se adapte a sus necesidades. Todos los edificios disponen de sistemas de seguridad avanzados para la comodidad y tranquilidad de los usuarios.
                            El nivel de acondicionamiento de los espacios se define con el cliente de acuerdo a sus requerimientos, nuestra oferta parte de oficinas en estado rústico hasta el más alto nivel de acondicionamiento.
                        </p>
                    </div>
                    

                    <div className='service-image'>
                        <Fade direction='right' triggerOnce='true'>
                            <img src={officeImage} alt='Office'/>
                        </Fade>
                    </div>
                </div>
                
                <div className='service'>
                    <div className='service-image relative'>
                        <Fade direction='left' triggerOnce='true'>
                            <img src={fieldImage} alt='Fields'/>
                        </Fade>
                    </div>
                    
                    <div className='service-content'>
                        <FontAwesomeIcon icon={faMap} className='icon-service text-slate-700'/>
                        <h3 class="text-1xl tracking-tight text-slate-700 sm:text-5xl md:text-6xl my-2">
                            Espacios
                        </h3>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            DESARROLLE SU PROPIO PROYECTO
                        </p>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            Contamos con diferentes tipos de predios para alquiler, donde su compañía puede libremente desarrollar un proyecto edilicio, ofreciendo a la vez escalabilidad a su proyecto. Contamos con un equipo de trabajo capaz de asesorarlo en la construcción de sus instalaciones o bien darle la total libertad para que usted mismo desarrolle su proyecto de acuerdo de forma particular. Los términos de alquiler son a mediano y largo plazo con condiciones muy favorables.
                            Contemplamos las necesidades de espacio de las empresas para brindarles las opciónes más conveniente. Contamos con naves logísticas desde 450 a 2400 metros 2.
                        </p>
                    </div>
                </div>

                <div className='service'>
                    <div className='service-content'>
                        <FontAwesomeIcon icon={faBoxesStacked} className='icon-service text-slate-700'/>
                        <h3 class="text-1xl tracking-tight text-slate-700 sm:text-5xl md:text-6xl my-2">
                            Depositos
                        </h3>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            SERVICIOS DE LOGÍSTICA
                        </p>

                        <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                            En Parque Empresarial Camino Ventura su compañía puede arrendar espacio en nuestros edificios y manejar su propia actividad logística o de manufactura en depósitos hechos a la medida de su negocio.
                        </p>
                    </div>

                    <div className='service-image'>
                        <Fade direction='right' triggerOnce='true'>
                            <img src={depositImage} alt='Office'/>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Export Services Component
export default Services;